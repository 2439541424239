import React from 'react';
import Moment from 'react-moment';

import ClockIcon from '../assets/images/clock.png';
import MarkerIcon from '../assets/images/marker.png';
import WFPIcon from '../assets/2022 update/COMPASS ROSE.svg';
import './EventSingle.css';
import Icon from 'react-fontawesome';




export default ({ bundled = false, sourceParam, data :
    {title, url, venue, event_type, description, location,
    datetime_start_buffer, address1, address2, city, state, zipcode, 
    name, website, group, group_url, organization, is_pinned}
}) => {

    function WFPEvent() {
        return <img src={WFPIcon} className='wfp-event-list-icon' />;
      };
      
    function AllyEvent() {
        return <img src={MarkerIcon} className='event-list-icon' />;
      };
    
      function WFPFlag() {
        if (organization.includes("Working Families" || "WFP")) {
            return <WFPEvent/> 
        } else {
            return <AllyEvent/>
        }
    }

    if (organization.includes("Working Families" || "WFP")) {
        var isWFP = true;
    } else {
        var isWFP = false;
    }

    if (is_pinned) {
        var isPriority = true;
    } else {
        var isPriority = false;
    }
    
    return (

    <div className={`${bundled && 'event-bundled-item'} event-list-item-container ${ isWFP ? "wfp-bling" : ""} ${ isPriority ? "priority-bling" : ""}`}>
        <div className='event-list-item-date'>
            <div className='event-list-date-view'>
                <Moment className='event-list-day-data' format="ddd" >{ datetime_start_buffer }</Moment>
                <Moment className='event-list-date-data' format="DD" >{ datetime_start_buffer }</Moment>
                <Moment className='event-list-month-data' format="MMM" >{ datetime_start_buffer }</Moment>
            </div>

        </div>
        <div className='event-list-item-info'>
            <h2 className='event-list-item-title'>
                <a href={`${url || website}${ sourceParam ? `?${sourceParam}` : ''}`} target='_blank'>{name || title}</a>
            </h2>
            <div className='event-list-item-desc event-list-item-event-type'>
                {organization }
            </div>
            <div className='event-list-item-desc event-list-item-event-type'>
                {event_type && event_type.event_type_mapping && event_type.event_type_mapping.display_name || "Volunteer Event"}
            </div>
            <div className='event-list-item-desc'>
                <img src={ClockIcon} className='event-list-icon' /> <Moment className='event-list-time-data' format="h:mm a" >{ datetime_start_buffer }</Moment>
            </div>
            
            {!bundled && <div className='event-list-item-desc'>
                <WFPFlag/>
                <div>  
                    <span>{venue}</span> {venue && address1 && (<br/>)}
                    <span>{[address1, address2, city, state, zipcode].filter(x => x).join(' ')}</span>
                </div>
            </div>}

           
        </div>
        { isPriority && <Icon name='thumb-tack' />}
    </div>
)};
