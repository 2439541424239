import React from 'react';
import Icon from 'react-fontawesome';
import './Navigation.css';

import WFPLogo from '../assets/2024_update/WFP-WORDMARK-STACKED-WHITE.png';

export default ({ handleNavClose, showBigCircle, handleNavOpen, isMobileNavVisible }) => (
  <>
    <header className='navigation'>
      <div className='navigation-area'>
        {showBigCircle && <div className='big-circle-area'>
          <a href='/' className='big-circle'></a>
        </div>}
        <a href='/' className='logo-area'>
          <img src={WFPLogo} className='logo-img'/>
        </a>
        <nav>
          <ul className='link-list'>   
            <li className='donate-area'>
              <a href='https://docs.google.com/document/d/e/2PACX-1vTW7b_oYDuM_CgCnCAvLnQxBlgVy_Bb1atOuciIiJu6EEg6E3gRcbN53Cy-TlCw_Zxg1zTk86hTl5P7/pub' className='donate-button'>Info for Event Hosts</a>
            </li>
         
            <li className='mobile-nav-open'>
              <a href='javascript: void(null)' onClick={handleNavOpen}>
                <Icon name='bars' size='2x' />
              </a>
            </li>
          </ul>
        </nav>
        {isMobileNavVisible &&
          <nav className='mobile-nav'>
            
            <div className='mobile-nav-viewport'>
              <a href='javascript: void(null)' className='mobile-nav-close' onClick={handleNavClose}>
                <Icon name='close' size='2x' />
              </a>
              <div className="mobile-list">
              <ul style={{}}>
              <li>
                <a href='/'>
                  <img src={WFPLogo} width='200px'/>
                </a>
              </li>
              <li className='donate-area'>
              <a href='https://docs.google.com/document/d/e/2PACX-1vTW7b_oYDuM_CgCnCAvLnQxBlgVy_Bb1atOuciIiJu6EEg6E3gRcbN53Cy-TlCw_Zxg1zTk86hTl5P7/pub' className='mobile-donate-button'>Info for Event Hosts</a>
              </li>
              <li className='donate-area'>
              <a href='https://act.workingfamilies.org/forms/join-the-wfp-challenge/?source=map' className='mobile-donate-button'>2022 Activist Challenge</a>
              </li>
              </ul>
              </div>
            </div>
          </nav>
        }
      </div>
    </header>

    <header className="paid-for-footer">
            <div className="paid-for-header">
          <a href='https://www.workingfamilies.org' target="_blank" className='paid-for-button'>Paid for by WFP National PAC — www.workingfamilies.org</a>
        </div>
    </header>
  </>
  )

/**
 *               <li><a href='https://cabanforqueens.com/about'>About</a></li>
              <li>
                <a href='https://cabanforqueens.com/issues'>Issues</a>
              </li>
              <li>
                <a href='https://store.cabanforqueens.com?ref=website'>Store</a>
              </li>
              <li>
                <a href='https://cabanforqueens.com/map'>Volunteer</a>
              </li>

 */
