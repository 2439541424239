export const LOAD_LANGUAGE = 'LANGUAGE_LOAD_LANGUAGE';
export const CHANGE_LANGUAGE = 'LANGUAGE_CHANGE_LANGUAGE';
export const LOAD_AGGREGATED_DATA = 'AGGDATA_LOAD_AGGREGATED_DATA';

export const SEARCH_SET_FILTERS = 'SEARCH_SET_FILTERS';
export const SEARCH_SET_RESULTS = 'SEARCH_SET_RESULTS';
export const SEARCH_ERROR_RESULTS = 'SEARCH_ERROR_RESULTS';
export const SEARCH_SET_SELECTED_RESULT = 'SEARCH_SET_SELECTED_RESULT';
export const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY';

export const EVENTS_LOAD_EVENTS = 'EVENTS_LOAD_EVENTS';
